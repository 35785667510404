import {Component} from 'preact';
import {customerService} from '../../services/customerService';
import style from './style.module.scss';
import FallBackAvatar from '../../assets/images/profile.png';
import FallBackAvatarMale from '../../assets/images/profile_male.png';
import FallBackAvatarFemale from '../../assets/images/profile_female.png';
import classNames from "classnames";
import {loyaltyDataService} from "../../services/loyaltyDataService";
import {i18nService} from "../../services/i18nService";

export default class UserProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {userData: false};

    customerService._subscribeForUser((user) => {
      this.setState({userData: user});
    });

    loyaltyDataService._subscribeForLoyaltyData((loyaltyData) => {
      this.setState({loyaltyData: loyaltyData});
    });
  }

  render(props, state) {

    if (state.userData) {
      let user = state.userData;
      let profile = user.profile ? user.profile : false;
      return (
          <div id={'userProfile'} className={style['user-container']}>
            <div class={style['image-container']}>
                <img className={style['user-image']}
                     src={this.getCustomerAvatar(profile)}/>
            </div>

              <div class={style["details"]}>
                <div class={style["card-details"]}>
                  <div class={style["card-info"]}>
                    {this._renderCard()}
                    <span class={style["card-number"]}>{state.loyaltyData && state.loyaltyData.cards && state.loyaltyData.cards.length > 0 && state.loyaltyData.cards[0].number}</span>
                  </div>
                  <div class={style["card-text"]}>
                    {i18nService.translate('dashboard_profile_customer_card_text')}
                  </div>
                </div>

                <div class={style["user-details"]}>
                  <div class={style.username}>{profile && profile.firstName ?
                    profile.firstName : ''} {profile && profile.lastName ?
                    profile.lastName : ''}</div>
                  <div class={style["user-email"]}>{user.email}</div>
                </div>

                <div class={style['user-edit']}>{this._renderEditProfile()}</div>

                <div class={style["points-container"]}>
                  <div class={style['points-label']}>{i18nService.translate('dashboard_profile_points_text')}</div>
                  <div class={style["points-text"]}>{state.loyaltyData && state.loyaltyData.totalPoints ?
                    state.loyaltyData.totalPoints :
                    '0'}</div>
                </div>
              </div>
          </div>
      );
    }
    return (
        <div class="container">
          <div class={style['user-container']}>
          </div>
        </div>
    );

  }

  _renderEditProfile() {
    return (
      <span onClick={() => location.href = '/customer-profile/#/private/customer-profile/change-user-data'}
            aria-hidden="true"
            class={classNames(style.icon, style.editProfile, style.solid)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <path
            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h274.9c-2.4-6.8-3.4-14-2.6-21.3l6.8-60.9 1.2-11.1 7.9-7.9 77.3-77.3c-24.5-27.7-60-45.5-99.9-45.5zm45.3 145.3l-6.8 61c-1.1 10.2 7.5 18.8 17.6 17.6l60.9-6.8 137.9-137.9-71.7-71.7-137.9 137.8zM633 268.9L595.1 231c-9.3-9.3-24.5-9.3-33.8 0l-37.8 37.8-4.1 4.1 71.8 71.7 41.8-41.8c9.3-9.4 9.3-24.5 0-33.9z"/>
        </svg>
      </span>
    );
  }

  _renderCard() {
    return (
      <span aria-hidden="true"
           class={classNames(style.icon, style.card, style.solid)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path
            d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 400H48V80h480v352zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2zM360 320h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8z"/>
        </svg>
      </span>
    );
  }

  getCustomerAvatar(profile) {
    if (profile) {
      if (profile.avatarUrl) {
        return profile.avatarUrl;
      }

      switch (profile.gender) {
        case 'MALE': {
          return FallBackAvatarMale;
        }
        case 'FEMALE': {
          return FallBackAvatarFemale;
        }
      }
    }
    return FallBackAvatar;
  }
}