class CountryService {

  constructor () {
    this.countryService = window['NY_COUNTRY_SERVICE'];
    if (!this.countryService) {
      console.error("could not find country-service in window['NY_COUNTRY_SERVICE'].");
    }
  }


  async getCountries() {
    const countries = await this.countryService.getCountries();
    return countries.filter(it => it.loyalty === true);
  }
}

export let countryService = new CountryService();