/* eslint-disable react/no-did-mount-set-state */
import {Component} from 'preact';
import style from './style.module.scss';
import SectionHeader from '../sectionHeader';
import {i18nService} from '../../services/i18nService';
import ReceiptInput from '../../components/receiptInput';
import {loyaltyDataService} from "../../services/loyaltyDataService";

export default class History extends Component {

  async componentDidMount() {

    loyaltyDataService._subscribeForHistoryData((history) => {
      this.setState({history: history});
    });

    let headline = await i18nService.translate('dashboard_points_history_headline');
    this.setState({headline: headline});

    const TransactionSubTypes = {
      RM: i18nService.translate('dashboard_points_history_type_purchase'),
      RMB: i18nService.translate('dashboard_points_history_type_purchase'),
      RMBX: i18nService.translate('dashboard_points_history_type_purchase'),
      ERM: i18nService.translate('dashboard_points_history_type_purchase'),
      PCI: i18nService.translate('dashboard_points_history_type_purchase'),
      FRI: i18nService.translate('dashboard_points_history_type_friends'),
      COU: i18nService.translate('dashboard_points_history_type_coupon'),
      CAN: i18nService.translate('dashboard_points_history_type_cancellation'),
      ERC: i18nService.translate('dashboard_points_history_type_cancellation'),
      CRC: i18nService.translate('dashboard_points_history_type_correction'),
      WLC: i18nService.translate('dashboard_points_history_type_welcome'),
      EVE: i18nService.translate('dashboard_points_history_type_event'),
      ELSE: i18nService.translate('dashboard_points_history_type_other')
    };
    this.setState({TransactionSubTypes: TransactionSubTypes});
  }

  render(props, state) {
    if (state && state.history && state.history.transactions &&
        state.history.transactions.length > 0) {

      let transactions = [];
      if (state.history.transactions && state.history.transactions.length > 3) {
        transactions = state.history.transactions.slice(0, 3);
      }
      else {
        transactions = state.history.transactions ?
            state.history.transactions :
            [];
      }

      return (
        <div id={'history'} class={style.container}>
          <SectionHeader>{state.headline}</SectionHeader>
          <div class={style.text}>
              {i18nService.translate('dashboard_points_history_text1')}
          </div>
          <div class={style.spacer}/>
          <div class={style.text}>
              {i18nService.translate('dashboard_points_history_text2')}
          </div>
          <div class={style.slider}>{transactions.map(
            this.renderTransaction.bind(this))}</div>

          <ReceiptInput/>
        </div>
      );
    }
    return (
      <div id={'history'} className={style.container}>
        <SectionHeader>{state.headline}</SectionHeader>
        <div className={style.text}>
            {i18nService.translate('dashboard_points_zero_history_text')}
        </div>
        <div className={style.spacer}/>
        <ReceiptInput/>
      </div>
    );
  }

  renderTransaction(transaction) {
    const SubTypeCodes = this.state && this.state.TransactionSubTypes ? this.state.TransactionSubTypes : {};
    return (
        <div class={style.transaction}>
            {transaction && transaction.date && <div class={style.date}>
              {new Date(transaction.date).toLocaleDateString("de-DE", {year: 'numeric', month: '2-digit', day: '2-digit'})}
            </div>}
            <div class={style.subType}>
                {SubTypeCodes.hasOwnProperty(transaction.subTypeCode) ? SubTypeCodes[transaction.subTypeCode] : SubTypeCodes['ELSE']}
            </div>
            <span class={style.points}>
              {(transaction.points >= 0) ? "+" + transaction.points : transaction.points.toString()}
            </span>
        </div>
    );
  }

}