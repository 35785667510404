import {Component} from 'preact';
import style from './style.module.scss';

import {route} from 'preact-router';
import CouponControls from '../partnerCouponControls';
import {loyaltyDataService} from "../../services/loyaltyDataService";
import {i18nService} from "../../services/i18nService";

export default class Coupons extends Component {

  constructor() {
    super();

    this.state = {
      selectedCoupon: {id: null},
      coupons: [],
      rewards: [],
    };
  }

  async componentDidMount() {

    loyaltyDataService._subscribeForLoyaltyData((data)=> {
      let loyaltyData = data;

      let coupons;
      try {
        coupons = loyaltyData.coupons.filter((coupon) => {
          if (coupon.validTo) {

            let toDate = new Date(coupon.validTo);
            let now = new Date();

            return now.getTime() < toDate.getTime();
          }
          return true
        });
        coupons.sort((couponA, couponB) => {
          return new Date(couponB.validFrom).getTime() - new Date(couponA.validFrom).getTime() ||
                 new Date(couponB.validTo).getTime() - new Date(couponA.validTo).getTime() ||
                 new Date(couponB.createDate).getTime() - new Date(couponA.createDate).getTime()
        });
        for (let coupon of coupons) {coupon.type = 'COUPON'}
      } catch (e) {
        coupons = [];
      }

      let rewards;

      try {
        rewards = loyaltyData.rewards;
        rewards.sort((rewardA, rewardB) => {
          return new Date(rewardB.validFrom).getTime() - new Date(rewardA.validFrom).getTime() ||
                 new Date(rewardB.validTo).getTime() - new Date(rewardA.validTo).getTime() ||
                 new Date(rewardB.createDate).getTime() - new Date(rewardA.createDate).getTime()
        });

        for (let reward of rewards) {reward.type = 'REWARD'}
      } catch (e) {
        rewards = [];
      }

      this.setState({
        coupons: coupons,
        rewards: rewards,
      });
    });
  }

  render(props, state) {

    return (
        <div class="is-clearfix">
          <div id={'coupons'} class={style['coupon-container']}>
            <div class={style['coupon-slider']}>
              {state.rewards.map(
                this._renderCoupon.bind(this))}
              {state.coupons.map(
                  this._renderCoupon.bind(this))}
            </div>
          </div>
        </div>
    );
  }

  _getImageSrc(coupon) {
    try {
      let image = null;

      for (let i of coupon.images) {
        let tag = false;
          for (let t of i.tags) {
            if (t === 'list_web') {
              image  = i;
              tag = true;
              break;
            }
          }
          if (tag)
            break;
        }

      if (image)
        return image.url;

      return '';
    } catch (err) {
      return '';
    }
  }

  _navigateToCouponDetails(coupon) {
    route('/coupon/' + coupon.id);
  }

  _renderCoupon(coupon) {
    let backgroundStyle = {
      backgroundImage: 'url(' + this._getImageSrc(coupon) + ')',
    };

    let validityString = this._getValidityString(
        coupon.validFrom, coupon.validTo, coupon.isAllDay);

    let translations = coupon.translations && coupon.translations.de;

    let hasPoints = !!(coupon.points && coupon.points !== 0);
    return (
      <div class={style.coupon}>
        <div class={style.card}>
          <div onClick={(_) => this._navigateToCouponDetails(coupon)} className={style['rich-media']} style={backgroundStyle}>
            {hasPoints &&
            <div class={style.pointsContainer}>
              <div className={style.points}>
                <div className={style.pointsValue}>{coupon.points}</div>
                <div className={style.pointsText}>{i18nService.translate('dashboard_coupons_pointsText')}</div>
              </div>
            </div>
            }
          </div>


          <div className={style['supporting-text']}>
            {translations && translations.title &&
            <div class={style.title}>{translations.title}</div>}
            <div className={style.subtitle}>{translations.subtitle}</div>
            <CouponControls coupon={coupon}/>
            <div class={style["date-container"]}>
              {!coupon.isUnlimited && <div class={style.date}>{this._renderCalendarIcon()}
                {validityString &&
                <span class={style.validity}>{validityString}</span>}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderCalendarIcon() {
    return (
      <span aria-hidden="true"
           class={[style.icon, style.solid].join(' ')}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"/>
        </svg>
      </span>
    );
  }

  _getValidityString(validFrom, validTo, isAllDay) {
    try {
      let fromDate = new Date(validFrom);
      let toDate = new Date(validTo);

      if (validFrom && fromDate.getTime() > new Date().getTime()) {
        return 'COMING SOON';
      }
      let options = {year: 'numeric', month: '2-digit', day: '2-digit'};

      if (validTo) {
          // if (!isAllDay)
            return i18nService.translate('dashboard_coupon_valid_to_date_time', toDate.toLocaleDateString('de-DE', options), toDate.toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'}));
          // else
          //   return i18nService.translate('dashboard_coupon_valid_to_date', toDate.toLocaleDateString('de-DE', options));
      } else return false;
    } catch (e) {
      return '';
    }

  }
}
