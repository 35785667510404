import {shareIdService} from './shareIdService';
import conf from "../index";
import {analyticsService} from "./analyticsService";

class CustomerService {

    constructor() {
        this.tag = 'LOYALTY_CLIENT';

        this.subscribers = [];
        this.customerNotAvailableSubscribers = [];
        this.customerNotAvailable = false;

        if (window && window.NY_CUSTOMER_SERVICE) {
            this.customerService = window.NY_CUSTOMER_SERVICE;
            // noinspection JSUnresolvedFunction
            this.customerService.subscribeForCustomer(this.tag, (user) => {
                this.currentUser = user;
                this.notifySubscribers(user);
            });

            this.customerService.subscribeForCustomerNotAvailable(this.tag, () => {
                this.customerNotAvailable = true;
                this.notifyCustomerNotAvailableSubscribers();
            });
        }
    }

    _subscribeForUser(callback) {
        this.subscribers.push(callback);
        if (this.currentUser) {
            callback(this.currentUser);
        }
    }

    _subscribeForUserNotAvailable(callback) {
        this.customerNotAvailableSubscribers.push(callback);
        if (this.customerNotAvailable) {
            this.notifyCustomerNotAvailableSubscribers();
        }
    }

    notifySubscribers(user) {
        for (let i = 0; i < this.subscribers.length; i++) {
            const subscriber = this.subscribers[i];
            subscriber(user);
        }
    }

    notifyCustomerNotAvailableSubscribers() {
        for (let i = 0; i < this.customerNotAvailableSubscribers.length; i++) {
            const subscriber = this.customerNotAvailableSubscribers[i];
            subscriber();
        }
    }

    async login(email, password, saveLogin) {
        if (this.customerService) {
            return this.customerService.login(email, password, saveLogin);
        }
    }

    async registerCustomer(
        email, password, firstName, lastName, countryId, postalCode, optInEmailAdvertisementConsent, optInEmailChannelNewsletter, optInEmailChannelLoyalty, saveLogin = true) {
        if (this.customerService) {
            const registerData = {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                countryCode: countryId,
                postalCode: postalCode,
                optInEmailAdvertisementConsent: optInEmailAdvertisementConsent,
                optInEmailChannelLoyalty: optInEmailChannelLoyalty,
                optInEmailChannelNewsletter: optInEmailChannelNewsletter
            };

            let shareId = shareIdService.getShareId();

            if (shareId) {
                registerData.shareId = shareId;
            }

            const fromCampaign = analyticsService.getAllParameters();
            if (Object.keys(fromCampaign).length > 0) {
                registerData.fromCampaign = fromCampaign;
            }

            return this.customerService.registerCustomer(registerData, saveLogin);
        }
    }

    async acceptLoyalty() {
        if (this.customerService) {
            let data = {shareId: shareIdService.getShareId()};
            return this.customerService.acceptLoyalty(data);
        }
    }

    async resendVerificationEmail() {
        if (this.customerService) {
            return this.customerService.resendVerificationEmail();
        }
    }

    async loadOptInState() {
        if (this.customerService) {
            return this.customerService.loadOptInState();
        }
    }

    async updateProfile(profile) {
        if (this.customerService) {
            return this.customerService.updateProfile(profile);
        }
    }

    async getReferralLink() {
        if (this.customerService) {
            let response = await this.customerService.getShareId();
            if (response && response['shareId']) {
                let shareId = response['shareId'];
                return `https://app.newyorker.de/share/get-more/${shareId}`
            }
        }
        return false;
    }

    async getShareId() {
        if (this.customerService) {
            let response = await this.customerService.getShareId();
            if (response && response['shareId']) {
                return response['shareId'];
            }
        }
        return false;
    }

    async getLoyaltyQRCode(rewardIds, couponIds) {
        if (this.customerService) {
            return this.customerService.getLoyaltyQRCode(rewardIds, couponIds);
        }
        return false;
    }

    setFirstVisit(active) {
        localStorage.setItem('LOYALTY_FIRST_VISIT', active)
    }
}

export let customerService = new CustomerService();