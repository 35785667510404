import {Component} from 'preact';
import style from './style.module.scss';
import {customerService} from '../../services/customerService';
import {i18nService} from "../../services/i18nService";

export default class CustomerCard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let result = await customerService.getLoyaltyQRCode();
    if (result.imageData) {
      this.setState(
          {imageData: result.imageData, cardNumber: result.cardNumber});
    }
  }

  render(props, state) {
    return (
        <div className={style['customer-card']}>
          <div className={style.close}>{this._renderCloseIcon(props)}</div>

          <div class={style.headline}>{i18nService.translate('dasgboard_mobile_custom_card_label')}</div>

          {state.imageData &&
          <div className={style['qr-container']}>
            <img className={style['qr-code']}
                 src={`data:image/png;base64,${state.imageData}`}/>
          </div>
          }

          <div className={style.info}>{i18nService.translate('dasgboard_mobile_custom_card_text')}</div>
          <div className={style['card-number-info']}>{i18nService.translate('dasgboard_mobile_custom_card_number')} {state.cardNumber}</div>

        </div>
    );
  }

  _renderCloseIcon(props) {
    return (
      <span aria-hidden="true"
            class={[style.icon, style.solid].join(' ')}
            onClick={() => props.closeCallback()}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
        </svg>
      </span>
    );
  }
}