import {Component} from 'preact';
import 'flatpickr/dist/themes/material_red.css';
import {German} from "flatpickr/dist/l10n/de"
import DatePicker from 'flatpickr';

export default class Flatpickr extends Component {

  constructor(props) {
    super(props);
    this.inputCallback = this.inputCallback.bind(this);
  }

  componentDidMount() {
    new DatePicker(this.base.querySelector('input'), {
      locale: German,
      maxDate: new Date(),
      dateFormat: 'd.m.Y',
      timeFormat: 'H:i',
      time_24hr: true,
      enableTime: false,
      onChange: this.inputCallback
    });
  }

  inputCallback(date) {
    if (this.props.inputCallback) {
      this.props.inputCallback(date[0]);
    }
  }

  render(props, state) {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}