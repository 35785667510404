import {Component} from 'preact';
import style from './style.module.scss';
import classNames from 'classnames';

export default class MapSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      input: '',
      showPlaceholder: true,
      selectedPlace: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectStore = this.selectStore.bind(this);
    this.clearInput = this.clearInput.bind(this);
  }

  render(props, state) {

    return (
        <div className={style.container}>
          <input
              className={style.input}
              placeholder={this.props.placeholder}
              type='text'
              value={this.state.input}
              onKeyUp={this.handleInputChange}
          />
          {this.state.input && this.state.input.length > 0 ?
            this._renderDeleteIcon() :
              this._renderSearchIcon()}
          {props.stores && props.stores.length > 0 &&
          <ul className={style.dropdown}>
            {props.stores.map(this.renderStore.bind(this))}
          </ul>}
        </div>
    );
  }

  _renderSearchIcon() {
    return (
      <span aria-hidden="true"
            class={classNames(style.icon, style.search, style.solid)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/>
        </svg>
      </span>
    );
  }

  _renderDeleteIcon() {
    return (
      <span aria-hidden="true"
            class={classNames(style.icon, style.times, style.solid)}
            onClick={this.clearInput}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
          <path
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
        </svg>
      </span>
    );
  }

  handleInputChange(event) {

    if (event.keyCode === 27) {
      this.clearInput()
    } else {
      let value = event.target.value;
      this.setState({input: value});
      if (this.props.searchCallback) {
        this.props.searchCallback(value);
      }
    }
  }

  renderStore(store) {

    let title = this.getStoreTitle(store);

    return (
        <li onClick={() => {
          this.selectStore(store);
        }}>{title}</li>
    );
  }

  getStoreTitle(store) {
    let title = store.address.address_line_1;
    if (store.address.address_line_2) {
      title = title + ', ' + store.address.address_line_2;
    }
    if (store.address.address_line_3) {
      title = title + ', ' + store.address.address_line_3;
    }
    return title;
  }

  selectStore(store) {
    this.setState(
        {selectedOption: store, input: this.getStoreTitle(store)});
    if (this.props.storeCallback) {
      this.props.storeCallback(store);
    }
  }

  clearInput() {
    if (this.props.clearCallback) {
      this.props.clearCallback();
    }
    this.setState({input: ''});
  }
}