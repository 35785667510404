import conf from '../index';

class I18nService {

    _translations = {};

    async _loadTranslations(locale) {
        try {
            let response = await fetch(`${conf.translationServiceUrl}/${locale}/${conf.translationServiceProjectId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET'
                });
            if (response.ok) {
                let json = await response.json();
                for (let key in json) {
                    this._translations[key] = json[key];
                }
            }
        } catch (e) {
            console.error('error loading translations: ', e);
            return false
        }

        return true;
    }

    translate(key, ...args) {
        if (args.length > 0) {
            return this.replaceWildcards(key, args)
        }
        return this._translations[key] ? this._translations[key].toString() : key.toString()
    }

    async initTranslations() {
        let language = conf.defaultLocale;

        // no defaultLanguage -> get it from the url
        const url = window.location.href;
        const fragments = url.substr(url.indexOf('newyorker.de/') + 13).split('/'); // split URL path in segments
        if (fragments && fragments.length > 0) {
            let langFromUrl = fragments[0];
            if (!langFromUrl || langFromUrl === '' || langFromUrl.startsWith("host") || langFromUrl.length > 2) {
                language = conf.defaultLocale // if no language is found in the URL
            } else {
                language = langFromUrl; // use the language from the url
            }
        }

        return await this._loadTranslations(language);
    }

    replaceWildcards(key, array) {
        let translation = this.translate(key);

        if (translation) {
            return translation.replace(/%[0-9]+\$s/g, (wildcard) => {
                let index = parseInt(wildcard.match(/\d+/g));
                return array[index - 1] ? array[index - 1] : wildcard;

            });
        }
        else
            return false;
    }
}

export let i18nService = new I18nService();