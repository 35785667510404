import {Component} from 'preact';
import style from './style.module.scss';
import MapSearch from '../mapSearch';
import {storesService} from '../../services/storesService';
import Marker from '../../assets/images/ic_store_marker_32x32.png';
import BigMarker from '../../assets/images/ic_store_marker_96x96.png';
import conf from '../../index';
import CircularProgress from "../circularProgress";

export default class Map extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      searchResults: [],
      selectedStore: {},
    };
  }

  componentWillMount() {
    this.loadGoogleMapsJS();
  }

  componentDidMount() {
    this.requestCurrentPosition();
    this.getStores();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mapIsReady &&
        prevState.currentLocation !== this.state.currentLocation) {
      this.recenterMap();
    }

    if (this.state.mapIsReady &&
        this.state.stores &&
        this.state.stores.length > 0) {

      this.renderMarkers(this.state.stores);

    }

    if (this.state.mapIsReady &&
        prevState.selectedStore !== this.state.selectedStore) {
      this.showStoreOnMap(this.state.selectedStore);
    }
  }

  render(props, state) {
    return (
        <div className={style.container}>
          <MapSearch
              placeholder={'Suche'}

              storeCallback={(store) => {
                this.setState({selectedStore: store, searchResults: []});
                if (this.props.storeCallback) {
                  this.props.storeCallback(store)
                }
              }}

              searchCallback={(searchTerm) => {
                this.searchForStores(searchTerm);
              }}

              clearCallback={()=> {
                this.setState({searchResults: []})
              }}

              stores={state.searchResults}
          />
          <div className={style.map} id="map">
            {!state.mapIsReady ? this._renderLoadInidcator() : null}
          </div>
        </div>
    );
  }

  _renderLoadInidcator() {
    return (
      <div class={style['loadindicator-container']}>
        <CircularProgress color={'#888888'} size={40} thickness={2} variant={'static'}/>
      </div>
    )
  }

  loadGoogleMapsJS() {
    let body = document.getElementsByTagName('body')[0];
    let tag = document.createElement('script');

    tag.type = 'text/javascript';
    tag.async = false;
    tag.src = `https://maps.googleapis.com/maps/api/js?key=${conf.mapsKey}&callback=createMap&libraries=visualization`;
    body.appendChild(tag);

    window.createMap = () => {
      this.createMap();
    };
  }

  createMap() {

    let defaultPos = new google.maps.LatLng(51.165691, 10.451526000000058);

    this.map = new google.maps.Map(document.getElementById('map'), {
      center: defaultPos,
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      scaleControl: true,
      styles: [
        {
          featureType: "poi.business",
          stylers: [
            {visibility: "off"}
          ]
        }]
    });

    this.setState({mapIsReady: true});
  }

  requestCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setState({
              currentLocation: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            });
          },
          () => {
          });
    }
  }

  recenterMap() {
    const map = this.map;
    const currentLocation = this.state.currentLocation;

    const maps = google.maps;

    if (map) {
      let center = new maps.LatLng(currentLocation.lat, currentLocation.lng);
      map.panTo(center);
    }
  }

  async getStores() {
    let stores = await storesService.getStores();
    this.setState({stores: stores});
  }

  renderMarkers(stores) {
    if (this.map) {

      for (let i = 0; i < stores.length; i++) {
        const store = stores[i];

        this.createMarker(store, this.state.selectedStore.id);
      }
    }
  }

  searchForStores(searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    if (!searchTerm || searchTerm.length <= 0) {
      this.setState({searchResults: []});
      return
    }
    let results = this.state.stores.filter((store) => {
      return (
          store.address &&
          (store.address.city &&
              store.address.city.toLowerCase().indexOf(searchTerm) >= 0)) ||
          (store.address.address_line_1 &&
              store.address.address_line_1.toLowerCase().indexOf(searchTerm) >=
              0) ||
          (store.address.address_line_2 &&
              store.address.address_line_2.toLowerCase().indexOf(searchTerm) >=
              0) ||
          (store.address.address_line_3 &&
              store.address.address_line_3.toLowerCase().indexOf(searchTerm) >=
              0) ||
          (store.address.headline &&
              store.address.headline.toLowerCase().indexOf(searchTerm) >= 0) ||
          (store.address.zip && store.address.zip.indexOf(searchTerm) >= 0);
    });
    if (results.length >= 5) {
      results = results.slice(0, 5);
    }
    this.setState({searchResults: results});
  }

  getStoreTitle(store) {
    let title = store.address.address_line_1;
    if (store.address.address_line_2) {
      title = title + ', ' + store.address.address_line_2;
    }
    if (store.address.address_line_3) {
      title = title + ', ' + store.address.address_line_3;
    }
    return title;
  }

  showStoreOnMap(selectedStore) {
    if (this.map && selectedStore && selectedStore.marker) {
      this.map.panTo(selectedStore.marker.getPosition());
      this.map.setZoom(12);
    }
  }

  createMarker(store, selectedStoreId) {
    if (store.marker) {
      let oldMarker = store.marker;
      oldMarker.setMap(null);
    }

    let title = this.getStoreTitle(store);

    let icon = Marker;
    if (store.id === selectedStoreId) {
      icon = BigMarker;
    }
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(store.location.latitude,
          store.location.longitude),
      title: title,
      icon: icon,
      map: this.map,
    });

    store.marker = marker;

    marker.addListener('click', ((store, marker) => {
      this.setState({selectedStore: store});
    }).bind(this, store));

  }
}