import style from './style.module.scss';
import MobilePhone from '../../assets/images/mobile_phone.png';
import AppleAppStore from '../../assets/images/apple_app_store.png';
import GooglePlay from '../../assets/images/google_play.png';
import AppGallery from '../../assets/images/huawei_app_gallery.png';
import {i18nService} from "../../services/i18nService";

const GetMobileApp = () => (
  <div class={style.container}>
    <div class={style.column}>
      <div class={style.left}>
        <img src={MobilePhone} alt="Mobile Phone"/>
      </div>
    </div>
    <div class={style.column}>
      <div className={style.right}>
        <div className={style.textBlock}>
          <div className={style.text}>{i18nService.translate('dashboard_getMobileApp_text')}</div>
        </div>
        <div className={style.logo}>
          <a href="https://itunes.apple.com/de/app/new-yorker/id391120743">
            <img src={AppleAppStore} alt="App Store Logo" className={style.img}/>
          </a>
        </div>
        <div className={style.logo}>
          <a href="https://play.google.com/store/apps/details?id=de.pilot.newyorker.android">
            <img src={GooglePlay} alt="Google Play Logo" className={style.img}/>
          </a>
        </div>
        <div className={style.logo}>
          <a href="https://appgallery.cloud.huawei.com/ag/n/app/C103424325?channelId=Redirect+to+store&id=0fc17f98eb63492b8ab5176c3ba61783&s=C4C4A61F28981CF9C14B834809610E3ACAA2D1FF06BDE677129BDD8E20C6A8E8&detailType=0&v=">
            <img src={AppGallery} alt="Huawei App Gallery Logo" className={style.img}/>
          </a>
        </div>
      </div>
    </div>
  </div>
);


export default GetMobileApp;