import {Component} from 'preact';
import style from './style.module.scss';
import TextInput from '../textInput';
import Button from '../button';
import ErrorMessage from '../errorMessage';
import {i18nService} from "../../services/i18nService";
import {loyaltyDataService} from "../../services/loyaltyDataService";

export default class ReceiptInput extends Component {

    constructor(props) {
        super(props);
        this.state = {code: '', errorMessage: false, successMessage: false};
    }

    async componentDidMount() {
        let inputPlaceholder = await i18nService.translate('dashboard_receipt_input_placeholder');
        this.setState({inputPlaceholder: inputPlaceholder})
    }

    render(props, state) {
        return (
            <div class={style["receipt-container"]}>
                <div class={style["receipt-input-headline"]}>
                    {i18nService.translate('dashboard_points_receipt_input_headline')}
                </div>

                <div class={style.text}>{i18nService.translate('dashboard_receipt_input_text')}</div>

                <div class={style["input-wrapper"]}>
                    <TextInput grey={true} placeholder={state.inputPlaceholder} type={'text'} inputCallback={(code) => {
                        this.setState({code: code});
                    }}/>
                    <div class={style["button-spacer"]}/>
                    <Button green={true} disabled={!state.code} label={'OK'}
                            onClickCallback={() => {
                                this.addReceipt(state.code);
                            }}/>
                </div>
                {state.successMessage && <div className={style['success-message']}>{state.successMessage}</div>}
                <div class={style["error-wrapper"]}>
                    <ErrorMessage message={state.errorMessage}/>
                </div>
            </div>
        );
    }

    async addReceipt(code) {
        try {
            await loyaltyDataService.addReceipt(code);
            loyaltyDataService.reload();
            this.setState({
                successMessage: i18nService.translate('dashboard_history_receipt_input_success'),
                errorMessage: false
            });
        } catch (e) {
            let errorMessageKey = 'dashboard_history_receipt_input_error';
            if (e.error) {
                errorMessageKey = this.parseErrorMessage(e.error);
            }
            this.setState({errorMessage: i18nService.translate(errorMessageKey), successMessage: false})
        }
    }

    parseErrorMessage(error) {
        switch (error) {
            case "VALIDATION_ERROR":
                return 'dashboard_history_receipt_validation_error';
            case "ALREADY_ADDED_RECEIPT":
                return 'dashboard_history_receipt_already_added_error';
            default:
                return 'dashboard_history_receipt_input_error';
        }
    }
}