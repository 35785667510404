class AnalyticsService {

  getCampaignId() {
    return this._getUrlParam('utm_id');
  }

  getCampaignSource() {
    return this._getUrlParam('utm_source');
  }

  getCampaignMedium() {
    return this._getUrlParam('utm_medium');
  }

  getCampaignName() {
    return this._getUrlParam('utm_campaign');
  }

  getCampaignTerm() {
    return this._getUrlParam('utm_term');
  }

  getCampaignContent() {
    return this._getUrlParam('utm_content');
  }

  getAllParameters() {
    const id = this.getCampaignId();
    const source = this.getCampaignSource();
    const medium = this.getCampaignMedium();
    const campaign = this.getCampaignName();
    const term = this.getCampaignTerm();
    const content = this.getCampaignContent();

    const params = {};
    if (id) {
      params['utm_id'] = id;
    }
    if (source) {
      params['utm_source'] = source;
    }
    if (medium) {
      params['utm_medium'] = medium;
    }
    if (campaign) {
      params['utm_campaign'] = campaign;
    }
    if (term) {
      params['utm_term'] = term;
    }
    if (content) {
      params['utm_content'] = content;
    }

    return params
  }

  _getUrlParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#/]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}

export let analyticsService = new AnalyticsService();