import {Component} from 'preact';
import style from './style.module.scss';
import Button from '../button';
import ErrorMessage from '../errorMessage';
import {loyaltyDataService} from '../../services/loyaltyDataService';
import {i18nService} from "../../services/i18nService";

export default class CouponControls extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partnerUrl: false,
            showLoadIndicator: false,
        };

        this._consumePartnerCoupon = this._consumePartnerCoupon.bind(this);
    }

    async componentDidMount() {
        if (this.props && this.props.coupon && this.props.coupon.externalCode &&
            this.props.coupon.externalCode.startsWith('pa_')) {
            try {
                const response = await loyaltyDataService.checkPartnerPromotion(this.props.coupon.externalCode);
                if (response && response.code) {
                    this.setState({partnerUrl: response.code});
                }
            } catch (_) {
            }
        }
    }

    render(props, state) {
        let coupon = props.coupon;
        if (coupon.externalCode &&
            coupon.externalCode.startsWith('pa_')) {
            return (
                <div className={style['coupon-controls']}>
                    <div className={style.center}>
                        {!this.state.partnerUrl &&
                        <Button loading={this.state.showLoadIndicator}
                                onClickCallback={() => {
                                    // noinspection JSIgnoredPromiseFromCall
                                    this._consumePartnerCoupon(coupon.externalCode);
                                }} label={i18nService.translate('partnercoupon_activate_text')}/>}
                        {this.state.partnerUrl &&
                        <Button
                            onClickCallback={() => window.open(this.state.partnerUrl, '_blank')}
                            label={i18nService.translate('partnercoupon_link_to_partner')}
                        />}
                    </div>

                    {this.state.showError &&
                    <div className={style["error-wrapper"]}>
                        <ErrorMessage message={i18nService.translate('error_message_partnercoupon_depleted')}/>
                    </div>}
                </div>
            );
        } else {
            return (
                <div className={style['coupon-controls']}>
                    <div className={style.center}>
                        <a href={'https://api.newyorker.de/public/storelink.html'}>
                            <Button label={i18nService.translate('partnercoupon_activate_in_app_text')}/>
                        </a>
                    </div>
                </div>
            );
        }
    }

    async _consumePartnerCoupon(externalId) {
        this.setState({showLoadIndicator: true, showError: false});
        try {
            const result = await loyaltyDataService.consumePartnerPromotion(externalId);
            if (result.code) {
                this.setState({
                    partnerUrl: result.code,
                    showLoadIndicator: false,
                });
            }
        } catch (e) {
            this.setState({
                showLoadIndicator: false,
                showError: true,
            });
        }
    }
}