import {Component} from 'preact';
import style from './style.module.scss';
import CircularProgress from '../circularProgress';

export default class Button extends Component {

  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  render(props, state) {

    const classNames = this.getClassNames(props);
    if (props.form) {

      return (
          <button disabled={props.disabled || props.loading} form={props.form}
                  className={classNames}
                  type='submit' value='Submit'>{props.label}
            {props.loading && this._renderLoadInidcator()}
          </button>
      );
    }
    return (
        <button disabled={props.disabled || props.loading} className={classNames}
                onClick={this.handleOnClick}>{props.label}
          {props.loading && this._renderLoadInidcator()}
        </button>
    );
  }

  handleOnClick(event) {
    event.stopPropagation();
    if (this.props.onClickCallback) {
      this.props.onClickCallback();
    }
  }

  getClassNames(props) {

    let classNames = [];
    classNames.push(style.button);
    if (props.textStyle) {
      classNames.push(style.text);
    }
    if (props.dark) {
      classNames.push(style.dark);
    }

    if (props.black) {
      classNames.push(style.black);
    }

    if (props.green) {
        classNames.push(style.green);
    }

    if (props.grey) {
      classNames.push(style.grey);
    }

    if (props.white) {
      classNames.push(style.white);
    }

    if (props.invalid !== undefined && !props.invalid) {
      classNames.push(style.valid)
    }

    return classNames.join(' ');

  }

  _renderLoadInidcator() {
    return (
        <div class={style['loadindicator-container']}>
          <CircularProgress variant={'static'}/>
        </div>
    )
  }
}