import {Component} from 'preact';
import style from './style.module.scss';
import SectionHeader from '../sectionHeader';
import {customerService} from '../../services/customerService';
import ShareButton from '../shareButton';
import {shortenerService} from '../../services/shortenerService';
import {i18nService} from "../../services/i18nService";

export default class ReferAFriend extends Component {

  constructor(props) {
    super(props);
    this.state = {};

    customerService._subscribeForUser(async (user) => {
      this.setState({user: user});
    });
  }

  async componentDidMount() {

    let referralLink;
    try {
      referralLink = await customerService.getReferralLink();
    } catch (_) {
    }

    try {
      if (referralLink) {
        referralLink = await shortenerService.short(referralLink);
      }
    } catch (_) {
    }

    if (referralLink) {
      this.setState({referralLink: referralLink});
    }

    let shareId;
    try {
        shareId = await customerService.getShareId();
    } catch (_) {
    }

    if (shareId) {
      this.setState({shareId: shareId});
    }


    let referralText;
    if (this.state.user) {
        let profile = this.state.user.profile ? this.state.user.profile : false;

        let replacements = [
            profile.firstName,
            this.state.shareId,
            this.state.referralLink

        ];

        try {
            referralText = await i18nService.replaceWildcards('loyalty_share_referral_message_text', replacements);
        } catch (_) {
        }

        if (referralText)
            this.setState({referralText: referralText});
    }
    else
        return false;
  }

  render(props, state) {
    return (
        <div className="is-clearfix">
          <div id={'referAFriend'} className={style.container}>
            <SectionHeader>{i18nService.translate('dashboard_refer_a_friend_headline')}</SectionHeader>
            {state.referralLink &&
            <div>
              <p class={style["referral-info"]}>{i18nService.translate('dashboard_refer_a_friend_text')}</p>
              <div className={style['link-container']}>
                <div className={style.link}>{state.referralLink}</div>
              </div>
              <div className={style['social-media-container']}>
                <ShareButton
                    type={'facebook'}
                    shareUrl={state.referralLink}/>

                <ShareButton type={'threads'}
                             shareUrl={state.referralLink}
                             shareText={state.referralText}/>

                <ShareButton type={'email'}
                             shareUrl={state.referralLink}
                             shareText={state.referralText}/>

                <ShareButton type={'pinterest'}
                             shareUrl={state.referralLink}
                             shareText={state.referralText}/>

                <ShareButton type={'whatsapp'}
                             shareUrl={state.referralLink}
                             shareText={state.referralText}
                             mobileOnly={true}/>

                <ShareButton type={'telegram'}
                             shareUrl={state.referralLink}
                             shareText={state.referralText}
                             mobileOnly={true}/>
              </div>
            </div>
            }
          </div>
        </div>
    );
  }
}
