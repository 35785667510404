import {Component} from 'preact';
import style from './style.module.scss';
import Header from '../../components/header';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import {i18nService} from '../../services/i18nService';
import {customerService} from '../../services/customerService';
import {route} from 'preact-router';
import ErrorMessage from '../../components/errorMessage';
import TextInput from '../../components/textInput';
import {shareIdService} from '../../services/shareIdService';

export default class AcceptTOS extends Component {

  constructor(props) {
    super(props);
    this.state = {registerButtonActive: true};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    let shareId = shareIdService.getShareId();

    this.setState(
        {
          shareId: shareId
        });

  }

  render(props, state) {
    return (
        <div className={style.accepttos}>
          <Header/>


          <div className="container">
            <div class={style["info-wrapper"]}>
              <div class={style.infoTextHeadline}>{i18nService.translate('loyalty_accepttos_headline')}</div>
              <div class={style.infoText}>{i18nService.translate('loyalty_accepttos_text')}</div>
            </div>
            <form id={'accept-tos'} onSubmit={this.handleSubmit}>
              <div className={style['flex-container']}>
                <div className={style['checkbox-container']}>
                    <Checkbox
                      label={i18nService.translate('loyalty_checkbox_terms_text', '/legal/terms-and-conditions/')}
                      required={true}
                      darkStyle={true}
                      name={'privacy'}
                      unsafeLabel={true}
                      inputCallback={() => {
                        this.setState((
                          prevState => ({termsChecked: !prevState.termsChecked})));
                      }}/>


                  <div class={style.privacy} dangerouslySetInnerHTML={{__html: i18nService.translate('loyalty_checkbox_privacy_text', '/legal/privacy/')}}/>

                  <div class={style.input}>
                    <TextInput
                      placeholder={i18nService.translate('get_more_placeholder_share_id')}
                      grey={true}
                      required={false}
                      value={state.shareId}
                      inputCallback={(input) => {
                        shareIdService.setShareId(input);
                        this.setState({shareId: input});
                      }}
                    />
                  </div>
                </div>
              </div>


              <div className={style['flex-container']}>
                <div class={style["accept-tos-button"]}>
                  <Button form='accept-tos'
                          disabled={!this.validate()}
                          label={i18nService.translate('loyalty_accepttos_button')}
                          onClickCallback={this.handleSubmit}/>
                </div>
              </div>
              <div className={style['required-info-container']}>
                <div className={style['required-info']}>{i18nService.translate('checkbox_required_hint')}</div>
              </div>
              <ErrorMessage message={state.errorMessage}/>
            </form>
          </div>
        </div>

    );
  }

  validate() {
    try {
      return this.state.termsChecked;
    } catch (e) {
      return false;
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({registerButtonActive: false});

    const response = await customerService.acceptLoyalty();

    this.setState({registerButtonActive: true});

    if (response.error && response.error.error) {
      this.setState({errorMessage: response.error.error});
    } else {
      customerService.setFirstVisit(true);
      route('/dashboard');
    }
  }
}