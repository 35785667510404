import conf from '../index';

class StoresService {

  async getStores() {
    try {
      const url = `${conf.storesBackendUrl}/store/query?limit:10000`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let json = await response.json();
        this.stores = json.items;
        return this.stores
      }
    } catch (ignored) {
      return []
    }

  }

}

export let storesService = new StoresService();