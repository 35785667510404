const SHARE_ID_KEY = 'NY_GETMORE_SHARE_ID';
class ShareIdService {

  setShareId(shareId) {
    sessionStorage.setItem(SHARE_ID_KEY, shareId)
  }

  getShareId() {
    return  sessionStorage.getItem(SHARE_ID_KEY);
  }

}

export let shareIdService = new ShareIdService();