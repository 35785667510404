import {Component} from 'preact';
import style from './style.module.scss';
import Header from '../../components/header';
import CouponControls from '../../components/partnerCouponControls';
import GetMobileApp from "../../components/getMobileApp";
import classNames from 'classnames';
import {loyaltyDataService} from "../../services/loyaltyDataService";
import {customerService} from "../../services/customerService";
import {route} from 'preact-router';
import Logo from '../../assets/images/get_more_logo_black.png';
import {i18nService} from "../../services/i18nService";

export default class CouponDetails extends Component {

  async componentDidMount() {
    const regWidget = document.querySelector("registration-widget");
    regWidget.addEventListener('ny-customer-logged-in', (e) => {
      loyaltyDataService.reload();
    });

    customerService._subscribeForUser((user) => {
      if (!user) {
        route('/');
      }
    });

    customerService._subscribeForUserNotAvailable(() => {
      this.setState({notLoggedIn: true});
    });

    loyaltyDataService._subscribeForLoyaltyData((data) => {
      if(!data) return

      this.setState({
        loggedIn: true,
        notLoggedIn: false
      });

      let loyaltyData = data;

      let coupons;
      try {
        coupons = loyaltyData.coupons;
      } catch (e) {
        coupons = [];
      }

      let coupon = this._findMatchingCoupon(this.props.id, coupons);

      if (coupon) {
        coupon.type = 'COUPON';
        this.setState({coupon: coupon});
      }

      if (!coupon) {

        let rewards;
        try {
          rewards = loyaltyData.rewards;
        } catch (e) {
          rewards = [];
        }

        let reward = this._findMatchingCoupon(this.props.id, rewards);
        if (reward) {
          reward.type = 'REWARD';
          this.setState({coupon: reward});
        }
      }

      if (this.state.coupon) {
        let coupon = this.state.coupon;
        if (coupon.externalCode.startsWith('pa_'))
          coupon.isPartnerCoupon = true;
      }
    });

    window.scrollTo(0,0);
  }

  render(props, state) {
    if (props && props.id && state && state.coupon) {

      return (
        <div class="container">
          <div class={style.couponDetails}>
            <div class={style.pageHeader}>
              <Header/>
            </div>
            <div class={[style.column, style.left].join(' ')}>{this._renderArrowLeft()}</div>
            <div class={[style.column].join(' ')}>
              {this._renderCoupon(state.coupon)}
              <div class={style.phone}>
                <GetMobileApp/>
              </div>
            </div>
          </div>
        </div>

      );
    }
    else {
        return (
          <div>
            <Header/>
            <div class="container">
              <registration-widget className={state.notLoggedIn ? classNames(style.registrationContainer, style.show) : style.registrationContainer}></registration-widget>
              {state.loggedIn && !state.coupon ? this._renderNoCouponFound() : null}
            </div>
          </div>
        );
    }
  }

  _renderNoCouponFound() {
    return (
      <div class={style.errorNoCoupon}>
        <div class={style.logo}>
          <img src={Logo} alt={'GET-MORE Logo'} />
        </div>
        <div class={style.errorMessage}>
          {i18nService.translate('error_message_no_coupon_found')}
        </div>
        <a class={style.backButton} onClick={() => route('/dashboard')}>{i18nService.translate('button_back_dashboard')}</a>
      </div>
    )
  }

  _getImageSrc(coupon) {
    try {
      let image = null;

      for (let i of coupon.images) {
        let tag = false;
        for (let t of i.tags) {
          if (t === 'list_web') {
            image  = i;
            tag = true;
            break;
          }
        }
        if (tag)
          break;
      }

      if (image)
        return image.url;
      return '';
    } catch (err) {
      return '';
    }
  }

  _renderCoupon(coupon) {
    let backgroundStyle = {
      backgroundImage: 'url(' + this._getImageSrc(coupon) + ')',
    };

    let validityString = this._getValidityString(
      coupon.validFrom, coupon.validTo, coupon.isAllDay);

    let translations = coupon.translations && coupon.translations.de;

    let hasPoints = !!(coupon.points && coupon.points !== 0);

    return (
      <div class={style.coupon}>
        <div class={style.card}>
          <div className={style['rich-media']} style={backgroundStyle}>
            {hasPoints &&
            <div class={style.pointsContainer}>
              <div className={style.points}>
                <div className={style.pointsValue}>{coupon.points}</div>
                <div className={style.pointsText}>{i18nService.translate('dashboard_coupons_pointsText')}</div>
              </div>
            </div>
            }
          </div>


          <div className={style['supporting-text']}>
            {translations && translations.title &&
            <div class={style.couponHeader}>
              <div class={style.title}>{translations.title}</div>
              <div className={style.subtitle}>{translations.subtitle}</div>
            </div>}
            <div className={coupon.isPartnerCoupon ?
              classNames(style.body, style["no-border"]) : style.body}>{translations.body}</div>
            {coupon.isPartnerCoupon ? null :
             coupon.type === 'COUPON' ?
                 <div className={style.legalText}>{i18nService.translate('loyalty_coupon_legal_text')}</div> :
             coupon.type === 'REWARD' ?
                <div className={style.legalText}>{i18nService.translate('loyalty_reward_legal_text')}</div> : null}
            <CouponControls coupon={coupon}/>
            <div className={style["date-container"]}>
              {!coupon.isUnlimited && <div className={style.date}>{this._renderCalendarIcon()}
                {validityString &&
                <span className={style.validity}>{validityString}</span>}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderCalendarIcon() {
    return (
      <span aria-hidden="true"
            class={[style.icon, style.calendar, style.solid].join(' ')}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"/>
        </svg>
      </span>
    );
  }

  _renderArrowLeft() {
    // history.back() reminds the scroll position so it's a better user experience instead of going back to the dashboard in every case
    const historyEmpty = window.history.length <= 2;

    return (
      <span aria-hidden="true"
            class={[style.icon, style.arrow, style.solid].join(' ')}
            onClick={() => historyEmpty ? route('/dashboard') : window.history.back()}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/>
        </svg>
      </span>
    );
  }

  _getValidityString(validFrom, validTo, isAllDay) {
    try {
      let fromDate = new Date(validFrom);
      let toDate = new Date(validTo);

      if (validFrom && fromDate.getTime() > new Date().getTime()) {
        return 'COMING SOON';
      }
      let options = {year: 'numeric', month: '2-digit', day: '2-digit' };

      if (validTo) {
        if (!isAllDay)
          return i18nService.translate('dashboard_coupon_valid_to_date_time', toDate.toLocaleDateString('de-DE', options), toDate.toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'}));
        else
          return i18nService.translate('dashboard_coupon_valid_to_date', toDate.toLocaleDateString('de-DE', options));
      } else return false;
    } catch (e) {
      return '';
    }

  }

  _findMatchingCoupon(id, coupons) {
    for (const coupon of coupons) {
      if (coupon.id === id) {
        return coupon;
      }
    }
    return null;
  }
}
