import {Component} from 'preact';
import {route, Router} from 'preact-router';

import Dashboard from '../routes/dashboard';
import {createHashHistory} from 'history';
import CouponDetails from '../routes/couponDetails';
import Home from '../routes/home';
import Login from '../routes/login';
import Register from '../routes/register';
import {customerService} from '../services/customerService';
import { i18nService } from '../services/i18nService';
import AdditionalUserData from '../routes/additionalUserData';
import AcceptTOS from '../routes/acceptTos';

// if (module.hot) {
//   require('preact/debug');
// }

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {i18nInit: false, firstName: false, user: null, isAuthenticated: false, currentUrl: ""};

    customerService._subscribeForUser(async (user) => {
      const isAuthed = !!user;
      const hasLoyalty = user && user.loyalty;
      const firstName = user && user.profile &&
          user.profile.firstName;
      this.setState({
        firstName: firstName,
        user: user,
        isAuthenticated: isAuthed,
        hasLoyalty: hasLoyalty,
      });
      await this.checkAuthentication(isAuthed, hasLoyalty);
    });

    i18nService.initTranslations()
        .then(() => this.setState({ i18nInit: true }));
  }

  async componentDidMount() {
    const isAuthed = await this.isAuthenticated();

    this.setState({isAuthenticated: isAuthed});
  }

  /** Gets fired when the route changes.
   *  @param {Object} event    "change" event from [preact-router](http://git.io/preact-router)
   *  @param {string} event.url  The newly routed URL
   */
  handleRoute = async (event) => {
      return this.setState({currentUrl: event.url}, ()=> {
          this.checkAuthentication(this.state.isAuthenticated, this.state.hasLoyalty);
      });
  };

  render(props, state) {
    if (state.i18nInit) {
      return (
          <div id="app" class="loyalty-client">
            <Router history={createHashHistory()} onChange={this.handleRoute}>
              <Home path="/:shareId?" firstName={state.firstName}
                    isAuthenticated={state.isAuthenticated}
                    hasLoyalty={state.hasLoyalty}/>
              <Dashboard path="/dashboard"/>
              <Login path="/login"/>
              <Register path="/register"/>
              <CouponDetails path="/coupon/:id"/>
              <CouponDetails path="/reward/:id"/>
              <AdditionalUserData path="/additionalData"/>
              <AcceptTOS path="/accepttos"/>
            </Router>
          </div>
      );
    }
  }

  async isAuthenticated() {
    if (this.state.user) {
      return true;
    }
  }

  async checkAuthentication(isAuthenticated, hasLoyalty) {
    switch (this.state.currentUrl) {
      case '/dashboard':
        if (!isAuthenticated) route('/', true);
        if (isAuthenticated && !hasLoyalty) route('/accepttos', true);
        break;

      case '/':
      case '/login':
      case '/accepttos':
        if (isAuthenticated && hasLoyalty) route('/dashboard', true);
        break;
    }
    return true;
  }
}
