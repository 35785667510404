import {Component} from 'preact';
import style from './style.module.scss';
import classNames from 'classnames';

/**
 * Styled Picker
 * required props:
 *  label: 'Example',
 *  options[{ name:'optionName' }]
 *
 * optional props:
 *  inputCallback: (option) => {}
 */
export default class Picker extends Component {

  constructor(props) {
    super(props);
    this.state = {collapsed: true, options: [], disabled: props.disabled};

    this.selectOption  = this.selectOption.bind(this)
  }

  render(props, state) {
    return (
        <div class={!state.selectedOption ? style.select : classNames(style.select, style["selected-item-border"])}>
          <select value={state.selectedOption} disabled={state.disabled} onChange={state.selectedOption}>
            {props.options.map(this.renderOption.bind(this))}
          </select>
          {state.selectedOption ?
              this.renderSelectedOption(state.selectedOption, props) :
              <label onClick={() => {
                this.setState(
                    (prevState) => ({collapsed: !prevState.collapsed}));
              }}>{props.label}</label>}

          {!state.collapsed && <ul class={style.dropdown}>
            {props.options.map(this.renderItem.bind(this))}
          </ul>}
        </div>
    );
  }

  renderOption(option) {
    return (<option value={option}>{option.name}</option>);
  }

  renderItem(option) {
    return (
        <li onClick={() => {
          this.selectOption(option);
        }}>{option.name}</li>
    );
  }

  selectOption(option) {
    this.setState({collapsed: true, selectedOption: option});
    if (this.props.inputCallback) {
      this.props.inputCallback(option)
    }
  }

  renderSelectedOption(selectedOption) {
    return (
        <div onClick={() => {
          this.setState(
              (prevState) => ({collapsed: !prevState.collapsed}));
        }} class={style['selected-option']}>
          {selectedOption.name}
        </div>
    );
  }
}