import {Component} from 'preact';
import style from './style.module.scss';
import Header from '../../components/header';
import Coupons from '../../components/coupons';
import UserProfile from '../../components/user-profile';
import History from '../../components/history';
import ReferAFriend from '../../components/referAFriend';
import Welcome from '../../components/welcome';
import GetMobileApp from '../../components/getMobileApp';
import Menu from '../../components/menu';
import DOIBanner from '../../components/doiBanner';

export default class Dashboard extends Component {

  render(props, state) {
    return (
      <div>
        <DOIBanner/>
        <Header/>
        <div className="container">
          <div class={style["profile-wrapper"]}>
            <UserProfile/>
          </div>
          <div className={style.dashboard}>
            <Welcome/>
            <div class={style["sticky-menu-wrapper"]}>
              <Menu>
                <Coupons/>
                <ReferAFriend/>
                <History/>
                <GetMobileApp/>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
