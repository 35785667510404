import {Component} from 'preact';
import style from './style.module.scss';

export default class InfoItem extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render(props, state) {

    if (props.infoData) {

      return (
        <div>
          <div class={style["image-container"]}>
            <img class={style.image} src={props.infoData.url} alt="start page item"/>
            <div class={style.overlay}/>
            <h2 class={style.headline}>{props.infoData.headline}</h2>
          </div>
          {props.unsafe ?
            <div class={style.text} dangerouslySetInnerHTML={{__html: props.infoData.text}}/> :
            <div className={style.text}>{props.infoData.text}</div>}
        </div>
      )
    }
  }
}