class LoyaltyDataService {

  constructor() {
    this.tag = 'LOYALTY_DATA_SERVICE';

    this.dataSubscribers = [];
    this.historySubscribers = [];


    if (window && window.NY_CUSTOMER_SERVICE) {
      this.customerService = window.NY_CUSTOMER_SERVICE;
      this.reload();
    }
  }

  _subscribeForLoyaltyData(callback) {
    if (this.dataSubscribers.length <= 0) {
      this.reload();
    }
    this.dataSubscribers.push(callback);
    if (this.currentLoyaltyData) {
      callback(this.currentLoyaltyData);
    }
  }

  _subscribeForHistoryData(callback) {
    if (this.historySubscribers.length <= 0) {
      this.reload();
    }
    this.historySubscribers.push(callback);
    if (this.currentHistoryData) {
      callback(this.currentHistoryData);
    }
  }


  notifyDataSubscribers(data) {
    for (let i = 0; i < this.dataSubscribers.length; i++) {
      const subscriber = this.dataSubscribers[i];
      subscriber(data);
    }
  }

  notifyHistorySubscribers(data) {
    for (let i = 0; i < this.historySubscribers.length; i++) {
      const subscriber = this.historySubscribers[i];
      subscriber(data);
    }
  }

  reload() {
    // noinspection JSUnresolvedFunction
    this.customerService.getLoyaltyData().then(data => {
      this.currentLoyaltyData = data;
      this.notifyDataSubscribers(data);
    });

    // noinspection JSUnresolvedFunction
    this.customerService.getLoyaltyHistory().then(data => {
      this.currentHistoryData = data;
      this.notifyHistorySubscribers(data);
    });
  }

  async addReceipt(receiptNumber) {
    if (this.customerService) {
      return this.customerService.addReceipt(receiptNumber);
    }
    return null;
  }

  async checkPartnerPromotion(promotionCode) {
    if (this.customerService) {
      return this.customerService.checkPartnerPromotion(promotionCode);
    }
    return null;
  }

  async consumePartnerPromotion(promotionCode) {
    if (this.customerService) {
      return this.customerService.consumePartnerPromotion(promotionCode);
    }
    return null;
  }
}

export let loyaltyDataService = new LoyaltyDataService();