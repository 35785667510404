import conf from '../index';

class ShortenerService {
  short = async (url) => {

    try {
      const response = await fetch(`${conf.shortenerUrl}/api/shorten`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({url: url}),
        method: 'Post',
      });

      if (response.ok) {
        const body = await response.json();
        return body['shortUrl'] ? body['shortUrl'] : '';
      } else {
        return {error: body};
      }
    } catch (e) {
      return e;
    }



  }
}

export let shortenerService = new ShortenerService();