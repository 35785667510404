import {Component} from 'preact';
import style from './style.module.scss';
import classNames from 'classnames';

export default class Checkbox extends Component {

  constructor(props) {
    super(props);
    this.state = {};

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  render(props, state) {
    let label = this.renderLabel(props);
    return (
        <div class={props.darkStyle ? classNames(style.container, style.dark): style.container} onClick={this.handleOnChange}>
          <input
              required={this.props.required}
              class={style.checkbox}
              type='checkbox'
              checked={this.state.isChecked}
              onChange={this.handleOnChange}
          />
          <label/>
          {label}
        </div>
    );
  }

  renderLabel(props) {
    if (props.unsafeLabel) {
      return (<span className={style.label}
                    dangerouslySetInnerHTML={{__html: `${this.props.label}${this.props.required ? '*' : ''}`}}/>)
    } else {
      return(<span className={style.label}>{this.props.label}{this.props.required ? '*' : ''}</span>)
    }
  }

  handleOnChange(event) {
    const target = event.target;
    this.setState((prevState => ({
      isChecked: !prevState.isChecked,
    })));
    if (this.props.inputCallback) {
      this.props.inputCallback(this.state.isChecked);
    }
  }
}