import {Component} from 'preact';
import style from './style.module.scss';
import {customerService} from "../../services/customerService";
import Button from "../button";
import {i18nService} from "../../services/i18nService";

export const OptInStates = {
  NO_REMINDER: 'NO_REMINDER',
  SHOW_DOI_REMINDER: 'SHOW_DOI_REMINDER',
  SHOW_VERIFICATION_REMINDER: 'SHOW_VERIFICATION_REMINDER',
};

export default class DOIBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: false,
      optInState: false,
      bannerDisplayed: false,
    };

    this.handleResend = this.handleResend.bind(this);
  }

  async componentDidMount() {
    customerService._subscribeForUser(async (user) => {
      this.setState({user: user});
      if (user) {
        const flags = await customerService.loadOptInState();
        this.setState({optInState: flags, bannerDisplayed: (flags.action === OptInStates.SHOW_DOI_REMINDER) || (flags.action === OptInStates.SHOW_VERIFICATION_REMINDER)});
      }
    });
  }

  render(props, state) {
    if (state.bannerDisplayed) {
      return (
        <div class={style['doi-container']}>
          <div class={style['doi-close']} onClick={() => this.setState({bannerDisplayed: false})}>{this.renderCloseIcon()}</div>
          <div class={style["doi-text"]}>
            {state.user.countryCode === 'DE' && state.optInState.advertisementConsent === true ? i18nService.translate('doi_with_advertisement_consent') : null}
            {state.user.countryCode !== 'DE' && state.optInState.advertisementConsent === true ? i18nService.translate('doi_with_advertisement_consent_international') : null}
            {state.optInState.emailVerified === false && state.optInState.advertisementConsent === false ? i18nService.translate('doi_no_advertisement_consent') : null}
          </div>

          <div class={style["doi-confirm-button"]}>
            <Button white={true}
                    label={i18nService.translate('profile_resend_verification_link')}
                    onClickCallback={this.handleResend}
                    disabled={state.buttonDisabled}
            />
          </div>
        </div>
          )
    }
  }

  async handleResend() {
    try {
      await customerService.resendVerificationEmail();
      this.setState({bannerDisplayed: false});
    } catch (ignored) {
    }
  }

  renderCloseIcon() {
    return (
      <span aria-hidden="true"
            class={`${style.icon} ${style.solid}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
          <path
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
        </svg>
      </span>
    );
  }
}