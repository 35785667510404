import {Component} from 'preact';
import style from './style.module.scss';
import Logo from '../../assets/images/get_more_logo_black.png';
import CustomerCard from "../customerCard";
import {customerService} from '../../services/customerService';

export default class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {displayGetMoreCard: false, user: false};
  }

  componentDidMount() {
    customerService._subscribeForUser((user) => this.setState({user: user}))
  }

  render(props, state) {
    return (
      <div class={style.header}>
        <img class={style.logo} src={Logo}/>
        <div class={style["mobile-only"]}>
          <h3 class={style.headline}>GET MORE club</h3>
          {state.user && state.user.loyalty ? this.renderCard(props, state) : null}
          {state && state.displayGetMoreCard && <CustomerCard closeCallback={() => {
            this.setState(prevState => ({displayGetMoreCard: !prevState.displayGetMoreCard}))
          }}/>}
        </div>
      </div>
    );
  }

  renderCard() {
    return (
      <div onClick={() => {
        this.setState(
          (prevState) => ({displayGetMoreCard: !prevState.displayGetMoreCard}));
      }}
           aria-hidden="true"
           class={[style.icon, style.solid].join(' ')}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path
            d="M528 32H48C21.5 32 0 53.5 0 80v16h576V80c0-26.5-21.5-48-48-48zM0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V128H0v304zm352-232c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16zm0 64c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16zm0 64c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16zM176 192c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zM67.1 396.2C75.5 370.5 99.6 352 128 352h8.2c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h8.2c28.4 0 52.5 18.5 60.9 44.2 3.2 9.9-5.2 19.8-15.6 19.8H82.7c-10.4 0-18.8-10-15.6-19.8z"/>
        </svg>
      </div>
    );
  }
}
