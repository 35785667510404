import {Component} from 'preact';
import style from './style.module.scss';

export default class ErrorMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render(props, state) {
      if (props.message) {

        return (
            <div className={style.error}>
              {props.message}
            </div>
        )
      } else {
        return null
      }
    }
}