import {Component} from 'preact';
import style from './style.module.scss';
import {customerService} from '../../services/customerService';
import {i18nService} from "../../services/i18nService";

export default class Welcome extends Component {

  constructor(props) {
    super(props);
    this.state = {firstVisit: false};
  }

  componentDidMount() {
    customerService._subscribeForUser(async (user) => {
      this.setState({user: user});
    });

    let firstVisit = null;
    const storageItem = localStorage.getItem('LOYALTY_FIRST_VISIT');
    if (storageItem) {
      firstVisit = JSON.parse(storageItem);

      if (firstVisit) {
        customerService.setFirstVisit(false);
        this.setState({firstVisit: true});
      } else {
        customerService.setFirstVisit(false);
        this.setState({firstVisit: false});
      }

    } else {
      customerService.setFirstVisit(false);
      this.setState({firstVisit: true});
    }
  }

  render(props, state) {
    if (state.firstVisit && state.user && state.user.profile &&
        state.user.profile.firstName) {
      return (
          <div className={style.welcome}>
            <h3 class={style.headline}>{i18nService.translate('dashboard_welcome_hello_text')} {state.user.profile.firstName},</h3>
            <div class={style.text}>{i18nService.translate('dashboard_welcome_first_visit_text')}</div>
          </div>
      );
    } else if (state.user && state.user.profile &&
        state.user.profile.firstName) {
      return (
          <div className={style.welcome}>
            <h3 class={style.headline}>{i18nService.translate('dashboard_welcome_hello_text')} {state.user.profile.firstName},</h3>
            <div class={style.text}>{i18nService.translate('dashboard_welcome_visit_text')}</div>
          </div>
      );

    }
    else {
      return null;
    }
  }
}