import {Component} from 'preact';
import style from './style.module.scss';
import classNames from 'classnames';

export default class ShareButton extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render(props, state) {

    let shareUrl = props.shareUrl;
    let shareText = props.shareText;
    switch (props.type) {
      case 'facebook':
        return this._renderFacebook(shareUrl);
      case 'twitter':
        return this._renderTwitter(shareUrl, shareText);
      case 'threads':
        return this._renderThreads(shareUrl);
      case 'google_plus':
        return this.renderGoogle(shareUrl);
      case 'email':
        return this._renderEmail(shareUrl, shareText);
      case 'pinterest':
        return this._renderPinterest(shareUrl, shareText);
      case 'whatsapp':
        return this._renderWhatsapp(shareUrl, shareText);
      case 'telegram':
        return this._renderTelegram(shareUrl, shareText);
      default :
        return this.renderError();
    }
  }

  _renderFacebook(shareUrl) {
    const href = `https://facebook.com/sharer/sharer.php?u=${shareUrl}`;
    return (
        <a class={style.link}
           href={href}
           target="_blank"
           aria-label="Share on Facebook">
          <div
              class={[style.button, style['button--facebook']].join(' ')}>
            <div aria-hidden="true"
                 class={[style.icon, style.solid].join(' ')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M448 80v352c0 26.5-21.5 48-48 48h-85.3V302.8h60.6l8.7-67.6h-69.3V192c0-19.6 5.4-32.9 33.5-32.9H384V98.7c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9H184v67.6h60.9V480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z"/>
              </svg>
            </div>
          </div>
        </a>
    );
  }

  _renderTwitter(shareUrl, shareText) {

    const href = `https://twitter.com/intent/tweet/?text=${shareText}&amp;url=${shareUrl}`;
    return (
        <a class={style.link}
           href={href}
           target="_blank" aria-label="Share on Twitter">
          <div
              class={[style.button, style.twitter].join(' ')}>
            <div aria-hidden="true"
                 class={[style.icon, style.solid].join(' ')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
              </svg>
            </div>
          </div>
        </a>
    );
  }

  _renderThreads(shareUrl) {

    const href = `https://threads.net/intent/post?text=${shareUrl}`;
    return (
        <a class={style.link}
           href={href}
           target="_blank" aria-label="Share on Threads">
          <div
              class={[style.button, style.threads].join(' ')}>
            <div aria-hidden="true"
                 class={[style.icon, style.solid].join(' ')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM294.2 244.3c19.5 9.3 33.7 23.5 41.2 40.9c10.4 24.3 11.4 63.9-20.2 95.4c-24.2 24.1-53.5 35-95.1 35.3h-.2c-46.8-.3-82.8-16.1-106.9-46.8C91.5 341.8 80.4 303.7 80 256v-.1-.1c.4-47.7 11.5-85.7 33-113.1c24.2-30.7 60.2-46.5 106.9-46.8h.2c46.9 .3 83.3 16 108.2 46.6c12.3 15.1 21.3 33.3 27 54.4l-26.9 7.2c-4.7-17.2-11.9-31.9-21.4-43.6c-19.4-23.9-48.7-36.1-87-36.4c-38 .3-66.8 12.5-85.5 36.2c-17.5 22.3-26.6 54.4-26.9 95.5c.3 41.1 9.4 73.3 26.9 95.5c18.7 23.8 47.4 36 85.5 36.2c34.3-.3 56.9-8.4 75.8-27.3c21.5-21.5 21.1-47.9 14.2-64c-4-9.4-11.4-17.3-21.3-23.3c-2.4 18-7.9 32.2-16.5 43.2c-11.4 14.5-27.7 22.4-48.4 23.5c-15.7 .9-30.8-2.9-42.6-10.7c-13.9-9.2-22-23.2-22.9-39.5c-1.7-32.2 23.8-55.3 63.5-57.6c14.1-.8 27.3-.2 39.5 1.9c-1.6-9.9-4.9-17.7-9.8-23.4c-6.7-7.8-17.1-11.8-30.8-11.9h-.4c-11 0-26 3.1-35.6 17.6l-23-15.8c12.8-19.4 33.6-30.1 58.5-30.1h.6c41.8 .3 66.6 26.3 69.1 71.8c1.4 .6 2.8 1.2 4.2 1.9l.1 .5zm-71.8 67.5c17-.9 36.4-7.6 39.7-48.8c-8.8-1.9-18.6-2.9-29-2.9c-3.2 0-6.4 .1-9.6 .3c-28.6 1.6-38.1 15.5-37.4 27.9c.9 16.7 19 24.5 36.4 23.6l-.1-.1z"/>
              </svg>
            </div>
          </div>
        </a>
    );
  }

  renderGoogle(shareUrl) {

    const href = `https://plus.google.com/share?url=${shareUrl}`;

    return (
        <a class={style.link}
           href={href}
           target="_blank"
           aria-label="Share on Google+">
          <div
              class={[style.button, style.google].join(' ')}>
            <div aria-hidden="true"
                 class={[style.icon, style.solid].join(' ')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M11.37 12.93c-.73-.52-1.4-1.27-1.4-1.5 0-.43.03-.63.98-1.37 1.23-.97 1.9-2.23 1.9-3.57 0-1.22-.36-2.3-1-3.05h.5c.1 0 .2-.04.28-.1l1.36-.98c.16-.12.23-.34.17-.54-.07-.2-.25-.33-.46-.33H7.6c-.66 0-1.34.12-2 .35-2.23.76-3.78 2.66-3.78 4.6 0 2.76 2.13 4.85 5 4.9-.07.23-.1.45-.1.66 0 .43.1.83.33 1.22h-.08c-2.72 0-5.17 1.34-6.1 3.32-.25.52-.37 1.04-.37 1.56 0 .5.13.98.38 1.44.6 1.04 1.84 1.86 3.55 2.28.87.23 1.82.34 2.8.34.88 0 1.7-.1 2.5-.34 2.4-.7 3.97-2.48 3.97-4.54 0-1.97-.63-3.15-2.33-4.35zm-7.7 4.5c0-1.42 1.8-2.68 3.9-2.68h.05c.45 0 .9.07 1.3.2l.42.28c.96.66 1.6 1.1 1.77 1.8.05.16.07.33.07.5 0 1.8-1.33 2.7-3.96 2.7-1.98 0-3.54-1.23-3.54-2.8zM5.54 3.9c.33-.38.75-.58 1.23-.58h.05c1.35.05 2.64 1.55 2.88 3.35.14 1.02-.08 1.97-.6 2.55-.32.37-.74.56-1.23.56h-.03c-1.32-.04-2.63-1.6-2.87-3.4-.13-1 .08-1.92.58-2.5zM23.5 9.5h-3v-3h-2v3h-3v2h3v3h2v-3h3"/>
              </svg>
            </div>
          </div>
        </a>
    );
  }

  _renderEmail(shareUrl, shareText) {

    const href = `mailto:?subject=${shareUrl}&body=${encodeURIComponent(shareText)}`;

    return (
        <a class={style.link}
           href={href}
           target="_self"
           aria-label="Share by E-Mail">
          <div
              class={[style.button, style.email].join(' ')}>
            <div aria-hidden="true"
                 class={[style.icon, style.solid].join(' ')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z"/>
              </svg>
            </div>
          </div>
        </a>
    );
  }

  _renderPinterest(shareUrl, shareText) {
    const href = `https://www.pinterest.com/?next=/pin/create/button/?url=${shareUrl}&amp;media=${shareUrl}&amp;description=${encodeURIComponent(shareText)}`;
    return (
        <a class={style.link}
           href={href}
           target="_blank" aria-label="Share on Pinterest">
          <div
              class={[style.button, style.pinterest].join(' ')}>
            <div aria-hidden="true"
                 class={[style.icon, style.solid].join(' ')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M448 80v352c0 26.5-21.5 48-48 48H154.4c9.8-16.4 22.4-40 27.4-59.3 3-11.5 15.3-58.4 15.3-58.4 8 15.3 31.4 28.2 56.3 28.2 74.1 0 127.4-68.1 127.4-152.7 0-81.1-66.2-141.8-151.4-141.8-106 0-162.2 71.1-162.2 148.6 0 36 19.2 80.8 49.8 95.1 4.7 2.2 7.1 1.2 8.2-3.3.8-3.4 5-20.1 6.8-27.8.6-2.5.3-4.6-1.7-7-10.1-12.3-18.3-34.9-18.3-56 0-54.2 41-106.6 110.9-106.6 60.3 0 102.6 41.1 102.6 99.9 0 66.4-33.5 112.4-77.2 112.4-24.1 0-42.1-19.9-36.4-44.4 6.9-29.2 20.3-60.7 20.3-81.8 0-53-75.5-45.7-75.5 25 0 21.7 7.3 36.5 7.3 36.5-31.4 132.8-36.1 134.5-29.6 192.6l2.2.8H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z"/>
              </svg>
            </div>
          </div>
        </a>
    );
  }

  _renderWhatsapp(shareUrl, shareText) {

    const href = `whatsapp://send?text=${shareText}%20${shareUrl}`;

    return (
        <a class={this.props.mobileOnly ? classNames(style.link, style.mobileOnly) : style.link}
           href={href}
           target="_blank"
           aria-label="Share on WhatsApp">
          <div
              class={[style.button, style.pinterest].join(' ')}>
            <div aria-hidden="true"
                 class={[style.icon, style.solid].join(' ')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M224 122.8c-72.7 0-131.8 59.1-131.9 131.8 0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6 49.9-13.1 4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8 0-35.2-15.2-68.3-40.1-93.2-25-25-58-38.7-93.2-38.7zm77.5 188.4c-3.3 9.3-19.1 17.7-26.7 18.8-12.6 1.9-22.4.9-47.5-9.9-39.7-17.2-65.7-57.2-67.7-59.8-2-2.6-16.2-21.5-16.2-41s10.2-29.1 13.9-33.1c3.6-4 7.9-5 10.6-5 2.6 0 5.3 0 7.6.1 2.4.1 5.7-.9 8.9 6.8 3.3 7.9 11.2 27.4 12.2 29.4s1.7 4.3.3 6.9c-7.6 15.2-15.7 14.6-11.6 21.6 15.3 26.3 30.6 35.4 53.9 47.1 4 2 6.3 1.7 8.6-1 2.3-2.6 9.9-11.6 12.5-15.5 2.6-4 5.3-3.3 8.9-2 3.6 1.3 23.1 10.9 27.1 12.9s6.6 3 7.6 4.6c.9 1.9.9 9.9-2.4 19.1zM400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM223.9 413.2c-26.6 0-52.7-6.7-75.8-19.3L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5 29.9 30 47.9 69.8 47.9 112.2 0 87.4-72.7 158.5-160.1 158.5z"/>
              </svg>
            </div>
          </div>
        </a>
    );
  }

  _renderTelegram(shareUrl, shareText) {

    const href = `https://telegram.me/share/url?text=${shareText}&amp;url=${shareUrl}`;

    return (
        <a class={this.props.mobileOnly ? classNames(style.link, style.mobileOnly) : style.link}
           href={href}
           target="_blank" aria-label="Share on Telegram">
          <div
              class={[style.button, style.pinterest].join(' ')}>
            <div aria-hidden="true"
                 class={[style.icon, style.solid].join(' ')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"/>
              </svg>
            </div>
          </div>
        </a>
    );
  }

  renderError() {

    return (
        <span>Du musst einen Typ angegeben</span>
    )
  }
}
