class TrackingService {

  constructor() {
    if (window && window.NY_TRACKER) {
      this.tracker = window.NY_TRACKER;
    }
  }

  trackClick(event) {
    if (this.tracker) {
      this.tracker.trackClick(event);
      return true;
    }
    return null;
  }
}


// TrackingEvent Object
export function TrackingEvent(value, label, action, category) {
  this.value = value;
  this.label = label;
  this.action = action;
  this.category = category;
}

export const RegistrationSuccessEvent = new TrackingEvent('', 'success', 'register', 'GET MORE club');
export const RegistrationFailedEvent = new TrackingEvent('', 'failed', 'register', 'GET MORE club');

const trackerService = new TrackingService();
export { trackerService as TrackerService};
