import './style/index.scss';
import App from './components/app';
import { render } from 'preact';

class Config {
    constructor() {
        this.publicPath = import.meta.env.VITE_PUBLIC_PATH;
        this.defaultLocale = import.meta.env.VITE_DEFAULT_LOCALE;
        this.storesBackendUrl  = import.meta.env.VITE_STORES_BACKEND_URL;
        this.translationServiceUrl  = import.meta.env.VITE_TRANSLATION_SERVICE_URL;
        this.translationServiceProjectId = import.meta.env.VITE_TRANSLATION_SERVICE_PROJECT_ID;
        this.mapsKey = import.meta.env.VITE_MAPS_KEY;
        this.shortenerUrl = import.meta.env.VITE_SHORTENER_URL;
        console.debug(this);
    }
}
const conf = new Config();
export default conf;

// render into some specific element:
render(<App />, document.getElementById('get-more-app'));
