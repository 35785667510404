import {Component} from 'preact';
import classNames from 'classnames';
import styles from './style.module.scss';

export default class CircularProgress extends Component {

  constructor(props) {
    super(props);
    this.state = {};

    props.color = props.color ? props.color : 'white';
    props.size = props.size ? props.size : 40;
    props.thickness = props.thickness ? props.thickness : 4;
    props.value = 0;
    props.variant = 'indeterminate';
  }

  render(props, state) {
    const {className, color, size, style, thickness, ...other} = props;

    const circleStyle = {};
    const rootStyle = {};
    const rootProps = {};
    const classes = styles;

    const divClasses = classNames(
        classes.root,
        {
          [classes[`color${color}`]]: color !== 'inherit',
          [classes.indeterminate]: true,
        },
        className,
    );

    const circleClasses = classNames(classes.circle, {
      [classes.circleIndeterminate]: true,
    });

    return (
        <div
            class={divClasses}
            style={{width: size, height: size, ...rootStyle, ...style}}
            role="progressbar"
            {...rootProps}
            {...other}
        >
          <svg class={classes.svg}
               viewBox={`${props.size / 2} ${props.size /
               2} ${props.size} ${props.size}`}>
            <circle
                className={circleClasses}
                style={circleStyle}
                cx={props.size}
                cy={props.size}
                r={(props.size - thickness) / 2}
                fill="none"
                stroke-width={thickness}
            />
          </svg>
        </div>
    );
  }

  getRelativeValue(value, min, max) {
    const clampedValue = Math.min(Math.max(min, value), max);
    return (clampedValue - min) / (max - min);
  }

  easeOut(t) {
    t = this.getRelativeValue(t, 0, 1);
    // https://gist.github.com/gre/1650294
    t = (t -= 1) * t * t + 1;
    return t;
  }

  easeIn(t) {
    return t * t;
  }

}