import {Component} from 'preact';
import style from './style.module.scss';

export default class SectionHeader extends Component {

  render(props) {

    return (
        <div class={style.container}>
            {props.separator === false ? null : <div class={style.separator} />}
            {props.children && <h2 class={style.headline}>{props.children}</h2>}
        </div>
    );
  }

}