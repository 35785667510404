import {Component} from 'preact';
import style from './style.module.scss';
import TextInput from '../../components/textInput';
import Button from '../../components/button';
import {customerService} from '../../services/customerService';
import {route} from 'preact-router';
import ErrorMessage from '../../components/errorMessage';
import Header from '../../components/header';
import classNames from 'classnames';
import {i18nService} from '../../services/i18nService';
import Checkbox from "../../components/checkbox";


export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: false,
    };

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
    this.handleStayLoggedIn = this.handleStayLoggedIn.bind(this);
  }

  async componentDidMount() {
    let emailText = await i18nService.translate('cp_placeholder_email');
    emailText ? this.setState({emailText: emailText}) : this.setState({emailText: ''});
    let passwordText = await i18nService.translate('cp_placeholder_password');
    passwordText ? this.setState({passwordText: passwordText}) : this.setState({passwordText: ''});
    let stayLoggedInText = await i18nService.translate('cp_checkbox_save_login');
    stayLoggedInText ? this.setState({stayLoggedInText: stayLoggedInText}) : this.setState({stayLoggedInText: ''});
  }

  render(props, state) {
    return (
        <div class={style.login}>
          <Header/>
          <div className={'container'}>
            <div className={style.container}>
              <div class={classNames(style['login-container'], style.wrapper)}>

                <div class={style['text-container']}>
                  <div class={state.errorMessage ? style.hide : style['info-text']}>
                    {i18nService.translate('login_header_text')}
                  </div>
                  <div class={style['error-wrapper']}>
                    {state.errorMessage &&
                    <div class={style["exclamation-point"]}>
                      {this._renderExclamationPoint()}
                    </div>}
                    {state.errorMessage &&
                    <div class={style['error-message']}>
                      <ErrorMessage
                        message={i18nService.translate('cw_login_error_message')}/>
                    </div>}
                  </div>
                </div>

                <div class={style.input}>
                  <TextInput placeholder={state.emailText}
                             type={'email'}
                             grey={true}
                             inputCallback={this.handleEmailInput}/>
                </div>
                <div class={style.input}>
                  <TextInput class={style.input}
                             type={'password'}
                             grey={true}
                             placeholder={state.passwordText}
                             inputCallback={this.handlePasswordInput}
                             returnCallback={this.handleLogin}/>
                </div>
                <div class={style["save-login-password-reset"]}>
                  <div>
                  <Checkbox
                      class={style.checkbox}
                      darkStyle={true}
                      label={state.stayLoggedInText}
                      required={false}
                      name={'stay-logged-in-checkbox'}
                      inputCallback={() => {
                        this.setState((
                            prevState => ({termsChecked: !prevState.termsChecked})));
                        this.setState({isChecked: this.state.termsChecked});
                      }}
                      />
                  </div>
                  <Button label={i18nService.translate('cpwi_reset_password')}
                          onClickCallback={this.handlePasswordReset}
                          textStyle={true}
                  />
                </div>
                <div class={style.row}>
                  <div class={style["button-register"]}>
                  <Button onClickCallback={this.handleRegister}
                          label={i18nService.translate('cp_button_register')}
                          textStyle={true}
                  />
                </div>
                <div class={style["button-login"]}>
                  <Button onClickCallback={this.handleLogin}
                          label={i18nService.translate('cp_button_login')}
                          disabled={!(state.email && state.password)}
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }

  _renderExclamationPoint() {
    return (
      <span aria-hidden="true"
            class={classNames(style.icon, style.exclamation, style.solid)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
        </svg>
      </span>
    );
  }

  handleEmailInput(input) {
    if (input) {
      this.setState({email: input});
    } else
      this.setState({email: ''});

  }

  handlePasswordInput(input) {
    if (input) {
      this.setState({password: input});
    } else
      this.setState({password: ''});
  }

  handleStayLoggedIn(e) {
    this.setState({isChecked: e.target.checked});
  }

  handlePasswordReset() {
    if (this.state.email) {
      location.assign(`/customer-profile/#/public/customer-profile/reset-password?email=${this.state.email}`);
    } else {
      location.assign(`/customer-profile/#/public/customer-profile/reset-password`);
    }
  }

  handleRegister() {
    route('/register');
  }

  async handleLogin() {
    if (this.state.password && this.state.email) {
      try {
        await customerService.login(this.state.email, this.state.password, this.state.isChecked);
        route('/dashboard', true);
      } catch (ignored) {
        this.setState({errorMessage: true});
      }
    }
  }
}