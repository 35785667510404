import {Component} from 'preact';
import style from './style.module.scss';
import classNames from 'classnames';

export default class TextInput extends Component {


  dontRecycle = () => {
    // wait 1 tick for the recycler to reclaim this component, then destroy its cached DOM tree
    Promise.resolve(this).then(this.clearBase);
  };

  clearBase = (component) => {
    // Note: __b is the compressed name (it's always this in a prod build, but nextBase is not a public API)
    component.nextBase = component.__b = null;
  };

  constructor(props) {
    super(props);

    this.state = {
      disabled: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleReturnEvent = this.handleReturnEvent.bind(this);
  }

  componentWillUnmount() {
    this.dontRecycle();
  }


  render(props, state) {

    if (props.value) {
      state.input = props.value;
    }

    if (props.disabled) {
      state.disabled = props.disabled
    }

    const inputType = this.getInputType(props.type);

    let labelStyle = style.label;
    if (props.errorMessage) {
      labelStyle = `${style.label} ${style["error-message"]}`
    }

    return (
        <div class={style.container}>
          <input
              required={props.required}
              minlength={props.minlength}
              maxlength={props.maxlength}
              type={inputType}
              class={props.dark ?
                  classNames(style.input, style.dark) :
                  props.grey ? props.errorMessage ? classNames(style.input, style.error) :
                  classNames(style.input, style.grey) :
                  style.input}
              name={props.name ? props.name : 'input'}
              readOnly={state.disabled}
              value={state.input}
              onInput={this.handleInputChange}
              onKeyUp={(e) => {
                this.handleReturnEvent(e);
              }}
              autocomplete={props.autocomplete}
              placeholder={`${this.props.placeholder ? this.props.placeholder : ''}${this.props.required ? '*' : ''}`}
          />
          <div class={labelStyle}>{this.props.errorMessage ? this._renderErrorMessage() : this.props.label}{this.props.required && !this.props.errorMessage && this.props.label && '*'}</div>
        </div>
    );
  }

  _renderErrorMessage() {
    return (
      <div class={style["error-message-container"]}>
        <span aria-hidden="true"
                  class={classNames(style.icon, style.exclamation, style.solid)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
          </svg>
        </span>
        <span>{this.props.errorMessage}</span>
      </div>
    );
  }

  getInputType(type) {
    switch (type) {
      case 'password':
        return 'password';
      case 'email':
        return 'email';
      default:
        return 'text';
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;

    if (this.props.type === 'numbers') {
      let numberValue = !!value.match(/\d+/g) ? value.match(/\d+/g).toString() : '';
      this.setState({input: numberValue});
      if (this.props && this.props.inputCallback) {
        this.props.inputCallback(numberValue);
      }
    } else {
      this.setState({
        input: value,
      });
      if (this.props && this.props.inputCallback) {
        this.props.inputCallback(value);
      }
    }
  }

  handleReturnEvent(keyEvent) {
    if (keyEvent && keyEvent.key && keyEvent.key === 'Enter') {
      if (this.props && this.props.returnCallback) {
        this.props.returnCallback();
      }
    }
  }
}