import {Component} from 'preact';
import style from "./style.module.scss";
import classNames from "classnames";
import {i18nService} from "../../services/i18nService";

export default class Menu extends Component {

  jumpTo(id, e) {
    e.preventDefault();
    let itemPos = document.getElementById(id).offsetTop + 310;

    if (!this.isInternetExplorer()) {
      window.scrollTo({top: itemPos, left: 0, behavior: 'smooth'});
    } else {
      document.getElementById(id).scrollIntoView();
    }
  }

  isInViewport = (elem) => {
    let bounding = document.getElementById(elem).getBoundingClientRect();
    let windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    return (bounding.top <= windowHeight) && ((bounding.top + bounding.height) >= 25);
  };

  isInternetExplorer() {
    return navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))
  }

  onScroll() {
    if (this.isInViewport('userProfile')) {
      this.setState({selectedItem: 0});
    }
    else if (this.isInViewport('coupons')) {
      this.setState({selectedItem: 1});
    }
    else if (this.isInViewport('referAFriend')) {
      this.setState({selectedItem: 2});
    }
    else if (this.isInViewport('history')) {
      this.setState({selectedItem: 3});
    }
  }


  constructor(props) {
    super(props);

    this.state = {
      selectedItem: 0
    };
  }

  componentDidMount() {
    let isScrolling = false;
    document.addEventListener('scroll', () => {
      if (isScrolling) {
        return false;
      }
      isScrolling = true;
      this.onScroll();

      window.setTimeout(() => isScrolling = false, 50);
    });

  }

  render(props, state) {
    return (
      <div class={style.menu}>
        <div class={style['menu-items']}>
          <a class={state.selectedItem === 0 ? classNames(style["menu-item"], style.active) : style["menu-item"]} onClick={this.jumpTo.bind(this, 'userProfile')}>{i18nService.translate('dashboard_profile_headline')}</a>
          <a class={state.selectedItem === 1 ? classNames(style["menu-item"], style.active) : style["menu-item"]} onClick={this.jumpTo.bind(this, 'coupons')}>{i18nService.translate('dashboard_coupons_headline')}</a>
          <a class={state.selectedItem === 2 ? classNames(style["menu-item"], style.active) : style["menu-item"]} onClick={this.jumpTo.bind(this, 'referAFriend')}>{i18nService.translate('dashboard_refer_a_friend_headline')}</a>
          <a class={state.selectedItem === 3 ? classNames(style["menu-item"], style.active) : style["menu-item"]} onClick={this.jumpTo.bind(this, 'history')}>{i18nService.translate('dashboard_points_history_headline')}</a>
        </div>
        <div class={style.content}>{props.children}</div>
      </div>
    );
  }
}